import React, { useContext } from "react";
import {
  StyledBusinessSlide,
  StyledBusinessSlideCard,
  StyledBusinessSlideCardBlock,
  StyledBusinessSlideCardMedia,
  StyledBusinessSlideCardContent,
  StyledBusinessSlideCardSmallText,
  StyledBusinessSlideCardCounter,
  StyledBusinessSlideCardText,
  StyledBusinessSlideCardTitle,
  StyledBusinessSlideCardCta,
  StyledBusinessSlideCardLogo,
  StyledUl,
} from "./style";
import {
  StyledParagraph, StyledLi,
} from "../globalstyle";
import { Button } from "@3beehivetech/components";
import BackgroundImage from "gatsby-background-image";
import Button_v1 from "../Button/Button";
import { DataContext } from "../../contexts/DataContext";
import useNavigate from "../../hooks/useNavigate";

export default ({
  count, theme, smallText, title, paragraph, cta, logo, image, ul,
}) => {
  const { pageImages } = useContext(DataContext);
  const imageData = pageImages.find(e => e.path === image)?.childImageSharp.fluid;
  const buttonChildren = (
    <div style="font-size: 18px; display: inline-block;">
      {cta?.label}
    </div>
  );
  
  return (
    <StyledBusinessSlide className="swiper-slide">
      <StyledBusinessSlideCard theme={theme}>
        <StyledBusinessSlideCardBlock>
          <StyledBusinessSlideCardMedia>
            <BackgroundImage fluid={imageData} style={{ position: "initial" }} />
          </StyledBusinessSlideCardMedia>
        </StyledBusinessSlideCardBlock>
        <StyledBusinessSlideCardBlock >
          <StyledBusinessSlideCardContent theme={theme}>
            {smallText && (
              <StyledBusinessSlideCardSmallText>
                <StyledBusinessSlideCardCounter>
                  {count < 10 ? `0${count}` : count}
                </StyledBusinessSlideCardCounter>
                {smallText}
              </StyledBusinessSlideCardSmallText>
            )}
            <StyledBusinessSlideCardText>
              <StyledBusinessSlideCardTitle
                dangerouslySetInnerHTML={{ __html: title }}
              />
              <StyledParagraph
                small
                dangerouslySetInnerHTML={{ __html: paragraph }}
              />
              {ul && (
                <StyledUl>
                  {ul.map((li, index) => (
                    <StyledLi key={index} small dangerouslySetInnerHTML={{ __html: li.text }} />
                  ))}
                </StyledUl>
              )}
            </StyledBusinessSlideCardText>
            {cta && <StyledBusinessSlideCardCta>
              {!cta.productId && (
                <Button onClick={useNavigate(cta.url)}>
                  {buttonChildren}
                </Button>
              )}
              {cta.productId && (
                <Button_v1
                  as="div"
                  theme="yellow"
                  noLine
                  noPadding
                  smaller
                  className="snipcart-add-item"
                  dataitemid={cta.productId}
                  dataitemurl={cta.url}
                  dataitemimage={image}
                  dataitemprice={cta.finalPrice}
                  dataitemname={cta.cartItemName}
                  dataitemweight={cta.weight}
                  dataitemdescription={cta.cartDescription}
                  dataitemcustom1name={cta.cartCustom1Name}
                  dataitemcustom1options={cta.cartCustom1Options}>{cta.label}</Button_v1>)}
            </StyledBusinessSlideCardCta>
            }
            <StyledBusinessSlideCardLogo src={logo} loading="lazy" />
          </StyledBusinessSlideCardContent>
        </StyledBusinessSlideCardBlock>
      </StyledBusinessSlideCard>
    </StyledBusinessSlide>
  );
};
