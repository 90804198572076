import React, { useEffect, useRef } from "react";
import {
  StyledBusinessSlider,
  StyledBusinessSwiper,
  StyledBusinessSwiperSlider,
  StyledBusinessSwiperControls,
  StyledBusinessSwiperControlPrev,
  StyledBusinessSwiperControlNext,
  StyledBusinessSwiperBullets,
} from "./style.jsx";

import Swiper, { Navigation, Pagination } from "swiper";
import { StyledGridRow } from "../../commons/Grid";
import Slide from "./Slide";
import Icon, { icons } from "../Icon/Icon";
import { Colors } from "../../commons/Theme";
import { Helmet } from "react-helmet";

Swiper.use([Navigation, Pagination]);

export default ({ items, id }) => {
  const sliderEl = useRef();
  const sliderPaginationEl = useRef();
  const prevArrowEl = useRef();
  const nextArrowEl = useRef();

  useEffect(() => {
    new Swiper(sliderEl.current, {
      allowTouchMove: "ontouchstart" in window,
      observer: true,
      observeParents: true,
      autoHeight: true,
      navigation: {
        prevEl: prevArrowEl.current,
        nextEl: nextArrowEl.current,
      },
      pagination: {
        el: sliderPaginationEl.current,
        clickable: true,
      },
    });
  }, [sliderEl]);

  const products = items.filter((e) => e.cta?.productId);

  return (
    <>
      {products.length > 0 ? (
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify(products.map(e => {
              const outOfStock = e.quantityLeft && e.quantityLeft[0] == "0";
              const description = e.ul 
                ? `${e.smallText} - ${e.title} - ${e.ul.map(x => x.text).join(", ")}` 
                : `${e.smallText} - ${e.title}`;
              return {
                "@context":"https://schema.org",
                "@type": "Product",
                "name": e.cta.cartItemName,
                "image": e.image,
                "sku": e.cta.productId,
                "brand": {
                  "@type": "Brand",
                  "name": "3Bee",
                },
                "description": description,
                "offers": {
                  "@type": "Offer",
                  "price": e.cta.finalPrice,
                  "priceCurrency": "EUR",
                  "availability": outOfStock ? "OutOfStock" : "InStock",
                },
              };
            }))}
          </script>
        </Helmet>
      ) : null}

      <StyledGridRow noMargin id="businessSlider">
        <StyledBusinessSlider>
          <StyledBusinessSwiper ref={sliderEl} className="swiper-container">
            <StyledBusinessSwiperSlider className="swiper-wrapper">
              {items.map((item, index) => (
                <Slide {...item} count={index + 1} key={index} />
              ))}
            </StyledBusinessSwiperSlider>
            <StyledBusinessSwiperControls>
              <StyledBusinessSwiperControlNext ref={nextArrowEl}>
                <Icon icon={icons.chevronRight} color={Colors.white} />
              </StyledBusinessSwiperControlNext>
              <StyledBusinessSwiperControlPrev ref={prevArrowEl}>
                <Icon icon={icons.chevronLeft} color={Colors.white} />
              </StyledBusinessSwiperControlPrev>
            </StyledBusinessSwiperControls>
            <StyledBusinessSwiperBullets ref={sliderPaginationEl} />
          </StyledBusinessSwiper>
        </StyledBusinessSlider>
      </StyledGridRow>
    </>
  );
};
