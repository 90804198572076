import styled, { css } from "styled-components";
import {
  BP,
  Colors,
  Rem,
  Ratio,
} from "../../commons/Theme";
import { StyledIcon } from "../Icon/style";

export const StyledBusinessSlider = styled.div`
`;

export const StyledBusinessSwiper = styled.div``;

export const StyledBusinessSwiperSlider = styled.div``;

export const StyledBusinessSwiperControls = styled.div`
  ${Ratio(375, 250)};
  margin-left: 5px;
  position: absolute;
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-flow: row-reverse;
  top: 0;
  left: 0;
  background-color: ${Colors.transparent};
  z-index: 3;
  pointer-events: none;
  cursor: pointer;

  @media (${BP.ipad}) {
    width: auto;
    height: auto;
    top: 20px;
    left: 52%;
    align-items: flex-start;
  }
`;

export const StyledBusinessSwiperControlPrev = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: ${Rem(48)};
  min-height: ${Rem(48)};
  background-color: ${Colors.yellow};
  pointer-events: all;

  @media (${BP.ipad}) {
    min-width: ${Rem(63)};
    min-height: ${Rem(63)};
  }

  ${StyledIcon} {
    width: ${Rem(10)};

    @media (${BP.ipad}) {
      width: ${Rem(14)};
    }
  }

  &.swiper-button-disabled {
    pointer-events: none;
    opacity: 0.7;
  }
`;

export const StyledBusinessSwiperControlNext = styled(
  StyledBusinessSwiperControlPrev,
)`
  margin-left: ${Rem(2)};
`;

export const StyledBusinessSlide = styled.div`
  overflow: hidden;
  padding-bottom: 10px;
  padding-left: 5px;
`;

export const StyledUl = styled.ul`
padding-top:${Rem(20)};
`;

export const StyledUlTitle = styled.div`
  padding-bottom:${Rem(5)};
`;

export const StyledBusinessSlideCard = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
  justify-content: flex-start;
  color: ${Colors.white};

  @media (${BP.ipad}) {
    flex-direction: row;
    align-items: stretch;
  }
`;

export const StyledBusinessSlideCardBlock = styled.div`
  width: 100%;
  border-radius: 25px;
  
  @media (${BP.ipad}) {
    width: 50%;
  }
`;

export const StyledBusinessSlideCardMedia = styled.div`
  ${Ratio(375, 250)};
  width: 100%;
  position: relative;
  overflow: hidden;
  box-shadow: 1px 3px 3px 3px rgba(0, 0, 0, 0.3);
  @media (${BP.ipad}) {
    ${Ratio(1, 1)};
    height: 100%;
  }
`;

export const StyledBusinessSlideCardContent = styled.div`
  height: 100%;
  padding: ${Rem(20)};
  background-color: ${Colors.black};
  ${(props) =>
    props.theme === "white"
    && css`
      color: ${Colors.black};
      background-color: ${Colors.grayLight};
    `};

  @media (${BP.ipad}) {
    padding: ${Rem(120)} ${Rem(40)} ${Rem(30)} ${Rem(40)};
    max-width: ${Rem(640)};
  }
`;

export const StyledBusinessSlideCardLogo = styled.img`
  position: absolute;
  right: 0;
  bottom: 0;
  height: ${Rem(110)};
`;

export const StyledBusinessSlideCardSmallText = styled.div`
  font-size: ${Rem(16)};
  margin-bottom: ${Rem(24)};
  font-weight: 300;
  letter-spacing: ${Rem(3)};
  text-transform: uppercase;

  @media (${BP.ipad}) {
    font-size: ${Rem(18)};
    margin-bottom: ${Rem(52)};
  }
`;

export const StyledBusinessSlideCardCounter = styled.span`
  display: inline-block;

  &:after {
    content: '';
    display: inline-block;
    width: ${Rem(20)};
    height: ${Rem(2)};
    margin: 0 ${Rem(12)};
    background-color: ${Colors.yellow};
    vertical-align: middle;
  }
`;

export const StyledBusinessSlideCardText = styled.div``;

export const StyledBusinessSlideCardTitle = styled.h2`
  font-size: ${Rem(25)};
  line-height: ${Rem(30)};
  margin-bottom: ${Rem(24)};

  @media (${BP.ipad}) {
    font-size: ${Rem(38)};
    line-height: ${Rem(40)};
    margin-bottom: ${Rem(28)};
  }
`;

export const StyledBusinessSlideCardParagraph = styled.p`
  font-size: ${Rem(16)};
  line-height: ${Rem(24)};

  @media (${BP.ipad}) {
    font-size: ${Rem(18)};
    line-height: ${Rem(30)};
  }
`;

export const StyledBusinessSlideCardCta = styled.div`
  margin-top: ${Rem(30)};
  a {
    text-decoration: none;
  }
`;

export const StyledBusinessSwiperBullets = styled.div`
  text-align: center;
  padding-top: 0;

  @media (${BP.ipad}) {
    padding-top: ${Rem(10)}
  }

  .swiper-pagination-bullet {
    width: ${Rem(10)};
    height: ${Rem(10)};
    margin: 0 ${Rem(8)} !important;
    border-radius: ${Rem(10)};
    background-color: ${Colors.gray};

    @media (${BP.ipad}) {
      width: ${Rem(16)};
      height: ${Rem(16)};
    }
  }

  .swiper-pagination-bullet-active {
    background-color: ${Colors.yellow};
  }
`;
